import { FC } from "react";
import Button from "ui/Button";

interface JoinUsSectionProps {}

const JoinUsSection: FC<JoinUsSectionProps> = () => {
  return (
    <article className="join-us-section">
      <strong className="join-us-section__text text-XL bold">
        Присоединяйся к глобальному Go‑сообществу: <br />
        Здесь становятся программистами!
      </strong>
      <Button
        className="join-us-section__button"
        variant="gradient"
        placeholder="Начать учиться БЕСПЛАТНО"
      />
    </article>
  );
};

export default JoinUsSection;
