import MemoWizard from "assets/Wizard";
import { Link, useRouteError } from "react-router-dom";

import "./ErrorPage.scss";

interface RouteError {
  status?: number;
  statusText?: string;
  message?: string;
}

function ErrorPage() {
  const error = useRouteError() as RouteError;
  console.error(error);

  const errors: Record<number, string> = {
    400: "неправильный запрос",
    401: "не авторизован",
    403: "доступ запрещен",
    404: "страница не найдена",
    500: "внутренняя ошибка сервера",
    502: "плохой шлюз",
    503: "сервис недоступен",
    504: "время ожидания истекло",
  };

  return (
    <div className="ErrorPage">
      <MemoWizard />
      <h1>{error.status ?? "oops"}</h1>
      <h2>{errors[error.status!] || error.statusText || error.message}</h2>
      <p>
        Вернуться на <Link to="/">Главную страницу</Link>
      </p>
    </div>
  );
}

export default ErrorPage;
