import { FC, useState } from "react";
import Button from "ui/Button";
import Textarea from "ui/Textarea";

interface ProfileSupportProps {}

const ProfileSupport: FC<ProfileSupportProps> = () => {
  const [message, setMessage] = useState("");

  return (
    <div className="ProfileSupport">
      <div className="ProfileSupport__wrap">
        <h1 className="ProfileSupport__title text-L">Написать в поддержку</h1>
        <Textarea
          className="ProfileSupport__textarea"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          placeholder="Сообщение"
          rows={8}
        />
        <Button
          type="submit"
          variant="primary"
          placeholder="Отправить"
          className="ProfileSupport__btn"
        />
      </div>
    </div>
  );
};

export default ProfileSupport;
