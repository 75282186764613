import { FC } from "react";
import { Link } from "react-router-dom";
import { setModal } from "store/useModalStore";
import { useUser } from "store/useUserStore";
import Button from "ui/Button";

interface WelcomeSectionProps {}

const WelcomeSection: FC<WelcomeSectionProps> = () => {
  const { isAuthorized } = useUser();

  const startLearnHandler = () => {
    setModal("Authorization");
  };

  return (
    <section className="welcome-section">
      <div className="welcome-section__wrap">
        <strong className="welcome-section__title text-2XL">
          Добро пожаловать,
          <br />в Вашу волшебную школу <br /> программирования <br />
          на Go!
        </strong>
        <p className="welcome-section__text text-S semi-bold">
          Изучайте, создавайте, достигайте новых высот{" "}
          <img src="./assets/imgs/Planet.svg" alt="планета земля" />
        </p>

        <div className="welcome-section__buttons">
          {/* TODO: Изменить на просто LINK без кнопки, но чтобы при переходе открывалась модалка с авторизацией */}
          <Button placeholder="Начать обучение" variant="gradient" onClick={startLearnHandler} />
          {/* {isAuthorized ? (
            <Link className="Button gradient" to={"courses"}>
              Начать обучение
            </Link>
          ) : (
            <Button
              placeholder="Начать обучение"
              variant="gradient"
              onClick={startLearnHandler}
            />
          )} */}
          {isAuthorized ? (
            <Link className="Button primary" to={"courses"}>
              Тренажеры
            </Link>
          ) : (
            <Button placeholder="Тренажеры" variant="primary" />
          )}
        </div>
      </div>
      <img className="welcome-section__img" src="./assets/imgs/Castle.png" alt="Замок" />
    </section>
  );
};

export default WelcomeSection;
