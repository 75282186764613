import { FC } from "react";

interface FirstSimulatorSectionProps {}

const FirstSimulatorSection: FC<FirstSimulatorSectionProps> = () => {
  return (
    <section className="first-simulator-section  basic-text-section">
      <div className="text-wrap">
        <h2>
          С первого тренажера — погрузитесь
          <br /> в рабочую среду
        </h2>
        <p>
          Мы объясним теорию простым и доступным языком, чтобы Вам было легче её
          понять. После изучения материала Вы сможете закрепить свои знания,
          решая практические задания в интерактивном тренажёре. По завершении
          выполнения заданий, Ваши решения будут проверены автотестами.
        </p>
      </div>
      <img
        src="./assets/imgs/first-simulator-section.svg"
        alt="светящаяся свеча"
      />
    </section>
  );
};

export default FirstSimulatorSection;
