import { useState, useEffect, ReactNode, ComponentType } from "react";
import { useModalName } from "store/useModalStore";
import Modal from "ui/Modal";

interface IModalProviderProps {
  children: ReactNode;
}

export function ModalProvider(props: IModalProviderProps) {
  const filename = useModalName();
  const [Component, setComponent] = useState<ComponentType | null>(null);

  useEffect(() => {
    if (filename) {
      import(`modules/${filename}`)
        .then((mod) => {
          setComponent(() => mod.default);
        })
        .catch((err) => {
          console.error(`Error loading module ${filename}`, err);
          setComponent(null);
        });
    }
  }, [filename]);

  return (
    <>
      {filename && Component ? (
        <Modal>
          <Component />
        </Modal>
      ) : (
        <Modal></Modal>
      )}
      {props.children}
    </>
  );
}
