import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { setModal } from "store/useModalStore";
import { useUserAuth } from "store/useUserStore";

interface ProtectedRouteProps {
  requiredRole: "user" | "admin" | undefined;
}

interface UserData {
  email: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ requiredRole }) => {
  const { isAuthorized, role, isExited } = useUserAuth();
  const userData = localStorage.getItem("user");
  let parsedUserData: UserData | null = null;

  if (userData) {
    parsedUserData = JSON.parse(userData) as UserData;
  }

  if (!isAuthorized && (!parsedUserData || !parsedUserData.email)) {
    if (!isExited) {
      setModal("Authorization");
    }

    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
