import { FC } from "react";

type ButtonVariant = "primary" | "secondary" | "gradient" | "round";
type ButtonSize = "S" | "M" | "L" | "XL";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  placeholder?: string;
  variant: ButtonVariant;
  icon?: JSX.Element;
  size?: ButtonSize;
}

const Button: FC<ButtonProps> = (props) => {
  return (
    <button
      {...props}
      className={`Button ${props.variant} ${props.className ?? ""}`}
    >
      {props.icon ?? ""}
      {props.placeholder ?? ""}
      {props.children ?? ""}
    </button>
  );
};

export default Button;
