import { FC } from "react";

interface DemandSectionProps {}

const DemandSection: FC<DemandSectionProps> = () => {
  return (
    <section className="demand-section basic-text-section">
      <div className="text-wrap">
        <h2>
          Востребованность профессии <br /> Go‑разработчика
        </h2>
        <p>
          На сайте HeadHunter на август 2024 года опубликовано больше 2 700
          вакансий программиста на Golang для начинающих и опытных. Язык не
          только любим программистами, но и достаточно востребован, позволяет
          эффективно решать коммерческие задачи.
        </p>
      </div>
      <img src="./assets/imgs/demand-section.svg" alt="рука с монетой" />
    </section>
  );
};

export default DemandSection;
