import * as React from "react";

function Gopher(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={258} height={451} fill="none" {...props} className="Gopher">
      <g filter="url(#prefix__filter0_d_1582_1363)">
        <path
          d="M17.937 309.89l.389.693-.792.051c-.009.002-.056.018-.138.114-.106.121-.23.327-.363.64-.264.624-.517 1.566-.753 2.816-.472 2.492-.858 6.102-1.169 10.584-.62 8.959-.934 21.339-1.042 35.089-.214 27.297.386 59.96 1.01 81.909H245.521v-69.624l-2.326-68.011-.005-.15.079-.129c2.566-4.168 5.989-11.521 7.886-19.985 1.898-8.467 2.258-17.994-1.236-26.551l-.033-.08-.004-.087c-.223-4.999-3.903-10.301-8.55-14.95-4.625-4.627-10.107-8.506-13.737-10.659l-.65-.385.608-.448 3.467-2.551 2.887-2.27 1.873-1.7.002-.001c1.166-1.079 2.385-2.931 3.336-5.202.95-2.265 1.617-4.909 1.707-7.548.09-2.637-.396-5.247-1.727-7.471-1.327-2.216-3.515-4.087-6.901-5.224-6.755-2.268-12.498.154-16.939 4.015-4.452 3.871-7.538 9.148-8.898 12.407l-.225.54-.498-.307c-.62-.381-1.248-.762-1.884-1.143l-.014-.008-.013-.009c-35.468-24.329-72.167-24.477-100.317-18.46-14.075 3.009-26.007 7.559-34.566 11.392a183.629 183.629 0 00-10.152 4.931c-.864.452-1.6.846-2.208 1.17l-.687.366a42.47 42.47 0 01-.862.448c-.2.099-.398.193-.535.227l-.322.079-.199-.266c-2.223-2.984-6.27-7.207-11.156-10.153-4.89-2.949-10.538-4.574-16.034-2.535-2.757 1.023-4.58 2.572-5.737 4.394-1.16 1.827-1.67 3.963-1.736 6.185-.135 4.444 1.505 9.143 3.075 12.122l4.486 6.475h7.878l.18.967c-2.016.775-5.168 2.176-8.055 3.849-1.444.836-2.811 1.735-3.935 2.651-1.13.922-1.98 1.836-2.429 2.694l-.047.091-.08.065c-2.876 2.333-5.442 6.467-7.486 10.748-2.034 4.26-3.52 8.599-4.265 11.29-.648 2.783-1.427 7.477-1.654 12.493-.228 5.033.103 10.332 1.636 14.35 3.129 8.199 7.584 16.794 9.418 20.062z"
          fill="#DDBB91"
          stroke="#857480"
        />
        <path
          d="M143.234 292.897l-.352-.281.244-.379c.816-1.27 1.268-3.123.221-4.717-1.063-1.617-3.793-3.174-9.859-3.514-6.057-.341-9.956.497-12.32 1.698-2.318 1.178-3.11 2.669-3.142 3.737l.707 2.501.127.448-.437.158c-2.665.968-6.233 2.618-8.853 5.057-2.59 2.412-4.217 5.547-3.206 9.571 1.926 3.385 3.471 5.288 4.881 6.286 1.369.969 2.641 1.106 4.128.845.608-.186 1.29-.374 2.033-.556l.573-.141.045.588.724 9.528c.188.924.69 2.424 1.588 3.898.908 1.489 2.198 2.914 3.94 3.717 1.309.603 3.024.795 4.839.731l.011-.001h.01l.866-.029c1.088-.033 1.936-.06 2.826-.193 1.083-.163 2.235-.487 3.918-1.202 1.225-.985 2.565-2.561 3.242-5.019.687-2.496.704-5.947-.845-10.679l-.426-1.3 1.164.72.904.559.03.018.027.022c.129.108.255.223.374.332l.119.108c.158.143.32.283.516.425.38.276.912.579 1.801.896 3.12.243 5.292-.563 6.753-1.91 1.481-1.365 2.293-3.344 2.565-5.534.272-2.188-.002-4.549-.655-6.618-.654-2.077-1.671-3.802-2.834-4.773l-6.247-4.997z"
          fill="#D9D9D9"
          stroke="#857480"
        />
        <path
          d="M130.509 330.522c-.307-.103.716-10.498 1.228-15.252l3.222.565 3.069.72c.461 1.116 1.381 5.084.768 9.151-.762 1.928-2.004 3.266-3.53 3.969-2.163.995-4.296 1.002-4.757.847zM119 316.117c-.042-.508 5.883-.894 8.44-.847l-.46 15.252c-1.074-.141-1.964-.128-3.069-.692-1.765-.903-3.53-3.828-4.144-5.381-.46-3.389-.679-7.278-.767-8.332z"
          fill="#fff"
        />
        <path
          className="eyeball"
          d="M251.258 290.665l.005-.026.008-.026c2.713-8.57 3.387-21.779-.472-33.956-3.853-12.156-12.207-23.245-27.537-27.703-30.881-8.979-50.062 2.438-55.737 9.13l-.029.034-.035.029c-6.372 5.24-12.572 16.196-14.063 29.096-1.489 12.874 1.719 27.651 14.106 40.561 24.772 25.817 54.612 16.079 64.54 11.086 3.347-1.683 7.549-5.722 11.23-10.889 3.674-5.156 6.781-11.367 7.984-17.336z"
          fill="#DCDCDC"
          stroke="#857480"
        />
        <path
          d="M224.656 231.535c16.602 6.746 22.762 37.125 18.952 54.13-2.554 11.398-11.039 23.928-16.501 27.342-12.45 7.784-35.901 19.048-59.02-6-11.006-11.924-15.554-25.43-13.955-37.717.872-13.715 8.321-25.89 13.955-31.43 4.13-4.061 16.139-10.573 29.074-11.144 15.58-.687 21.876 2.536 27.495 4.819z"
          fill="#fff"
        />
        <path
          className="eyeball"
          d="M102.608 288.017c1.754-8.879 2.52-21.314-1.139-32.629-3.648-11.286-11.699-21.472-27.642-25.936-16.05-4.493-27.314-3.765-35.479-1.098-8.168 2.667-13.287 7.29-17.04 10.708-5.96 5.428-13.265 16.673-15.848 29.791-2.578 13.091-.451 28.017 12.394 40.907 12.868 12.913 26.613 15.848 38.302 14.757 11.713-1.093 21.381-6.233 26.02-9.49 2.852-2.002 7.363-5.69 11.462-10.421 4.105-4.737 7.762-10.479 8.97-16.589z"
          fill="#DCDCDC"
          stroke="#857480"
        />
        <path
          d="M76.744 231.574c16.606 6.755 22.767 37.174 18.956 54.202-2.554 11.413-13.493 22.019-18.956 25.438-12.451 7.794-36.573 21.731-59.696-3.35-23.123-25.081-5.236-59.07 5.519-69.658 4.13-4.067 14.542-10.347 27.48-10.918 15.582-.688 21.078 2 26.697 4.286z"
          fill="#fff"
        />
        <path
          d="M133.279 354.832c38.985 2.832 62.883 56.551 64.471 81.197H66.5c.145-2.783 1.456-8.908 2.022-14.013 3.118-28.143 12.401-44.855 17.882-52.685 1.909-2.727 3.606-5.365 9.52-9.332 5.913-2.847 16.874-6.038 37.355-5.167z"
          fill="#FFD8A9"
        />
        <path
          d="M119.54 312.757c4.312-.722 8.888-.722 13.973-.188 2.542.533 4.681.866 8.644 3.2 1.294.762 2.543.593 3.56.533 2.365-.139 3.519-.761 5.084-2.133 3.646-3.195 1.987-8.928.509-13.382-1.922-3.062-5.732-5.7-7.81-7.287-2.71 2.505-8.221 6.335-16.5 5.5-4.064-1.112-6.737-3.546-9.25-5.634-.542.046-3.884 1.275-6.102 2.667-2.217 1.253-3.321 3.577-4.083 4.754-3.696 5.707.183 8.587 1.183 9.882 1.33 1.723 3.252 4.733 10.792 2.088z"
          fill="#958B7F"
        />
        <path
          d="M140.148 308.722c-1.279.428-8.142.849-12.648 3.278-.733.395-5.72.357-9.5 1.5-3.053.571-3.117.222-3.99.222-2.026-.29-3.343-1.429-6.396-6-.288-.687-.727-2.143-.727-2.858 0-2.248 1.091-4.21 2.617-5.85 2.642-2.839 7.153-5.289 8.996-6.014a23.013 23.013 0 003.5 3.5c1.977 2.514 5.821 2.936 7.71 3.078 1.939-.047 4.797 1.572 8.694 3.143 8.169 2.572 3.419 5.44 1.744 6.001z"
          fill="#CCBDAC"
        />
        <path
          d="M118.151 288.141c1.096-2.547 5.072-4.679 13.001-4.27 4.094-.182 8.031.391 11.123 2.377.977.628 1.725 1.723 1.725 2.912 0 .849-.313 1.529-.47 2.038-.313 1.019-1.252 1.947-1.88 2.548-1.985 1.898-6.841 5.008-11.436 5.095-2.037-.17-2.977-.51-4.544-1.359-4.49-2.434-6.607-4.521-7.363-6.963-.233-.752-.47-1.529-.156-2.378z"
          fill="#454342"
        />
        <path
          d="M131.453 284.863c2.793-.145 4.723.837 6.833 2.429.436.571 1.308 1.886 1.308 2.571.031.229.013.858 0 1.143-.291 1.286-.711 2.535-1.163 3-.906.934-1.12 1.334-2.907 2.286-1.018.715-.25.143-2.908 1.286-1.848.286-3.75.154-4.942-.286-2.12-.781-4.28-2.014-5.815-3.286-1.454-1-1.89-1.857-2.326-2.571-.291-.714-.727-2-.436-2.715.187-.586.436-.857.872-1.285.796-.782 2.208-1.547 4.07-2 2.348-.573 4.398-.754 7.414-.572z"
          fill="#595755"
        />
        <path
          d="M121.535 288.278c.596-.72 1.121-1.051 2.035-1.286.339-.143 1.075-.286 1.308.286.233.571-.775 1.095-1.308 1.285-.727.429-1.454 1-1.745 2.858-.436.857-1.308.095-1.308-.429-.043-1.058.337-1.894 1.018-2.714z"
          fill="#000"
        />
        <path
          className="eye"
          d="M176.935 281.7l.022-.009.024-.008c3.721-1.146 5.683-3.658 6.626-6.308.944-2.654.858-5.433.458-7.06-1.066-2.625-2.53-4.457-4.368-5.69-1.847-1.238-4.108-1.897-6.804-2.108-.187 0-.374-.004-.561-.008-.802-.017-1.632-.035-2.67.233-1.271.328-2.872 1.097-4.99 2.908-1.829 2.068-2.31 4.82-2.14 7.469.17 2.649.988 5.119 1.665 6.524.712 1.258 2.21 2.986 4.367 4.04 2.144 1.049 4.962 1.445 8.371.017z"
          fill="#000"
          stroke="#857480"
        />
        <path
          className="eye"
          d="M179.579 266.341c.117-3.086-1.889-4-3.489-3.714-2.166.76-2.616 2-2.616 3.143 0 1 .477 2.046 1.308 2.714 1.066.857 4.681.943 4.797-2.143z"
          fill="#fff"
        />
        <path
          className="eye"
          d="M16.14 277.849l.02.026.015.028c1.39 2.467 5.753 5.748 12.646 3.625 3.737-1.151 5.596-3.436 6.45-5.857.863-2.448.707-5.07.331-6.848-.73-3.452-4.55-7.603-9.837-8.022-.148 0-.305-.003-.468-.006-.911-.014-2.052-.033-3.428.324-1.62.42-3.583 1.371-5.779 3.528-1.515 1.489-2.029 4.09-1.868 6.757.16 2.659.98 5.194 1.918 6.445z"
          fill="#000"
          stroke="#857480"
        />
        <path
          className="eye"
          d="M31.29 266.34c.117-3.086-1.889-4-3.488-3.714-2.167.76-2.617 2-2.617 3.143 0 1 .478 2.046 1.309 2.714 1.066.857 4.68.943 4.797-2.143z"
          fill="#fff"
        />
      </g>
      <path
        d="M33 231l.535-.243c4.973-3.73 28.31-49.38 40.462-59.521 6.418-6.398 16.131-19.193 19.256-25.45 3.125-9.386 12.5-37.543 18.75-65.7 5-22.526 2.083-46.929 0-56.315-9.375 0-13.75 8.135-43.75 15.643C85.753 11.883 108.878 5 118.253 5c9.375 1.043 21.875 6.257 37.5 28.157 8.929 12.514 22.917 67.786 31.25 103.243 3.676 15.643 10.684 45.11 15.625 50.057 6.25 6.257 15.163 31.286 20.371 40.672-11.458-6.258-30.502-13.766-53.002-6.258-28.125 9.386-62.5 9.386-68.75 9.386-5 0-17.248-11.257-28.123-12.514L56 221.614c-5.367 1.469-19.534 7.819-22.465 9.143-.213.16-.392.243-.535.243z"
        fill="#857C74"
      />
      <path
        d="M74.502 170.814l-.505.422m0 0C61.325 181.811 36.49 231 32.999 231c0 0 17-7.743 23-9.386l17.125-3.871C84 219 96.247 230.257 101.247 230.257c6.25 0 40.625 0 68.75-9.386 22.5-7.508 41.544 0 53.002 6.258-5.208-9.386-14.121-34.415-20.371-40.672-4.941-4.947-11.949-34.414-15.625-50.057-8.333-35.457-22.321-90.729-31.25-103.243-15.625-21.9-28.125-27.114-37.5-28.157-9.375 0-32.5 6.883-50 34.414 30-7.508 34.375-15.643 43.75-15.643 2.083 9.386 5 33.789 0 56.315-6.25 28.157-15.625 56.314-18.75 65.7-3.125 6.257-12.838 19.052-19.256 25.45z"
        stroke="#857480"
      />
      <path
        d="M76.25 165.134l23.102 8.057c31.3 10.917 68.335 12.863 100.785 5.642l-6.846-28.646c-32.45 7.222-59.384 5.234-90.684-5.682L87.344 136.4l-11.095 28.734z"
        fill="#533D4D"
      />
      <path
        d="M113.388 183.473l41.015 4.947a6.69 6.69 0 007.44-5.846l3.306-27.473c.441-3.671-2.173-7.006-5.84-7.448l-41.016-4.947a6.689 6.689 0 00-7.439 5.846l-3.306 27.473a6.694 6.694 0 005.84 7.448z"
        fill="#E6C067"
      />
      <path
        d="M133.521 152.183c9.17-.125 12.758 3.279 13.405 4.997l-7.347 3.031c1.013-1.422 2.122-4.023-1.362-4.684-3.483-.661-6.477 2.838-7.538 4.67-1.237 3.333-2.355 10.378 3.064 11.885 5.419 1.507 8.059-1.86 8.701-3.731 0 0-1.839-.589-7.547-.715 1.402-1.11 1.142-2.515.383-3.277l12.577 1.191-1.242 10.65-5.557 4.029.669-5.734c-.209-.02-.731.818-4.012 1.69-7.349 1.955-12.791-3.282-13.747-7.513-1.243-5.502.382-16.363 9.553-16.489z"
        fill="#272933"
      />
      <path
        className="stars"
        d="M210.727 174.734c-.314-.049-.299-.497.018-.524 4.297-.36 12.649-1.359 13.584-3.727.992-2.511 2.827-12.194 3.573-16.265.055-.301.497-.286.533.017.527 4.541 2.012 16.002 3.701 17.075 1.605 1.022 9.136 2.105 12.646 2.565.304.039.314.468.013.522-2.809.492-5.619 1.075-8.34 1.925-1.372.428-2.892.947-4.058 1.865-1.357 1.069-1.648 2.341-2.015 3.924-.827 3.564-1.385 7.197-1.935 10.811-.045.305-.497.295-.535-.01-.47-3.993-1.817-12.305-5.061-14.727-2.332-1.74-8.593-2.907-12.124-3.451zM33.727 169.734c-.314-.049-.299-.497.018-.524 4.297-.36 12.649-1.359 13.584-3.727.992-2.511 2.827-12.194 3.573-16.265.055-.301.497-.286.532.017.528 4.541 2.013 16.002 3.702 17.075 1.605 1.022 9.136 2.105 12.646 2.565.304.039.315.468.013.522-2.81.492-5.619 1.075-8.34 1.925-1.372.428-2.892.947-4.058 1.865-1.357 1.069-1.648 2.341-2.015 3.924-.827 3.564-1.385 7.197-1.935 10.811-.045.305-.497.295-.535-.01-.47-3.993-1.817-12.305-5.06-14.727-2.332-1.74-8.594-2.907-12.125-3.451zM84.671 53.83a.188.188 0 01.013-.374c3.237-.258 9.529-.972 10.233-2.666.747-1.796 2.129-8.722 2.691-11.634.042-.215.375-.204.401.012.398 3.248 1.516 11.446 2.788 12.214 1.21.73 6.882 1.505 9.527 1.834a.187.187 0 01.009.373c-2.116.352-4.232.77-6.282 1.377-1.033.306-2.179.678-3.057 1.335-1.022.764-1.241 1.674-1.518 2.806-.622 2.55-1.043 5.148-1.457 7.733-.034.218-.375.211-.403-.007-.354-2.856-1.369-8.802-3.812-10.534-1.757-1.245-6.474-2.08-9.133-2.468zM164.645 14.83c-.2-.035-.191-.355.011-.374 2.739-.258 8.062-.972 8.658-2.666.633-1.796 1.802-8.722 2.277-11.634.036-.215.318-.205.34.012.336 3.248 1.283 11.446 2.359 12.214 1.023.73 5.823 1.505 8.061 1.834.194.028.2.335.008.373-1.791.352-3.581.77-5.316 1.377-.874.307-1.843.678-2.586 1.335-.865.764-1.051 1.674-1.285 2.806-.527 2.55-.882 5.148-1.233 7.733-.029.218-.317.211-.341-.007-.3-2.856-1.158-8.802-3.226-10.534-1.486-1.245-5.477-2.08-7.727-2.468z"
        fill="#DBB763"
      />
      <defs>
        <filter
          id="prefix__filter0_d_1582_1363"
          x={0}
          y={192}
          width={258}
          height={258.286}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1582_1363"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1582_1363"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

const MemoGopher = React.memo(Gopher);
export default MemoGopher;
