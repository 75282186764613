const rootElement = document.getElementById("root");

export const removeInert = () => {
  if (rootElement) {
    rootElement.removeAttribute("inert");
  }
};

export const setInert = () => {
  if (rootElement) {
    rootElement.setAttribute("inert", "");
  }
};

export const setModalMaxWidth = (modalElement: HTMLDivElement) => {
  if (modalElement.children.length >= 1) {
    const elementToStyle = modalElement.children[0] as HTMLElement;
    const childrenStyles = window.getComputedStyle(elementToStyle);
    let maxWidth = parseFloat(childrenStyles.maxWidth) || 0;

    if (maxWidth) {
      const modalElementStyles = window.getComputedStyle(modalElement);
      const paddingLeft = parseFloat(modalElementStyles.paddingLeft) || 0;
      const paddingRight = parseFloat(modalElementStyles.paddingRight) || 0;

      maxWidth += paddingLeft + paddingRight;

      modalElement.style.maxWidth = `${maxWidth}px`;
      modalElement.style.width = "100%";
    }
  }
};
