import { Outlet } from "react-router-dom";
import "./App.scss";
import AppFooter from "./components/AppFooter";
import AppHeader from "./components/AppHeader";
import SimpleBarReact from "simplebar-react";

import "simplebar-react/dist/simplebar.min.css";
import { useEffect, useRef } from "react";
import { setUserData } from "store/useUserStore";

function App() {
  const scrollableNodeRef = useRef(null);

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      setUserData(JSON.parse(userData));
    }
  }, []);

  return (
    <div className="App">
      <AppHeader scrollableNodeRef={scrollableNodeRef} />
      <SimpleBarReact
        scrollableNodeProps={{ ref: scrollableNodeRef }}
        style={{ height: "calc(100% - var(--header-height))" }}
      >
        <main>
          <Outlet context={{ scrollableNodeRef }} />
        </main>
        <AppFooter />
      </SimpleBarReact>
    </div>
  );
}

export default App;
