import { FC } from "react";

interface WhyGoSectionProps {}

const WhyGoSection: FC<WhyGoSectionProps> = () => {
  return (
    <section className="why-go-section">
      <h2 className="why-go-section__title">
        Почему изучение Go — <br />
        лучший выбор для новичков?
      </h2>
      <ul className="why-go-section__list">
        <li className="why-go-section__item">
          <img src="./assets/imgs/simple-syntax.svg" alt="Волшебный посох" />
          <p>Простой синтаксис</p>
        </li>
        <li className="why-go-section__item">
          <img src="./assets/imgs/strong-typing.svg" alt="Рука со звездами" />
          <p>Строгая типизация</p>
        </li>
        <li className="why-go-section__item">
          <img
            src="./assets/imgs/rich-standard-library.svg"
            alt="Волшебная книга"
          />
          <p>Богатая стандартная библиотека</p>
        </li>
        <li className="why-go-section__item">
          <img src="./assets/imgs/built-in-tools.svg" alt="Бутылка с зельем" />
          <p>Встроенный инструментарий</p>
        </li>
        <li className="why-go-section__item">
          <img
            src="./assets/imgs/excellent-documentation.svg"
            alt="Глобус со звездачками"
          />
          <p>Отличная документация</p>
        </li>
        <li className="why-go-section__item">
          <img
            src="./assets/imgs/parallelism.svg"
            alt="Волшебная палочка с сердцем"
          />
          <p>Параллелизм</p>
        </li>
      </ul>
    </section>
  );
};

export default WhyGoSection;
