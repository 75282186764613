import { FC } from "react";
import { AchievementsData } from "./constants/AchievementsData";

interface ProfileAchievementsProps {}

const ProfileAchievements: FC<ProfileAchievementsProps> = () => {
  return (
    <div className="ProfileAchievements">
      <h1 className="ProfileAchievements__title text-L">Достижения</h1>
      <ul className="ProfileAchievements__list">
        {AchievementsData.map((achievement, index) => {
          return (
            <li className="ProfileAchievements__item" key={index}>
              <div className="ProfileAchievements__item-img">
                <img src="/assets/imgs/achievement.svg" alt="" />
              </div>
              <div className="ProfileAchievements__item-text">
                <p className="text-S semi-bold">{achievement.name}</p>
                <p className="text-XS ">{achievement.desc}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProfileAchievements;
