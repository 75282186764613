import MemoLogo from "assets/Logo";
import { FC, RefObject } from "react";
import { Link } from "react-router-dom";
import { setModal } from "store/useModalStore";
import { useUser } from "store/useUserStore";

interface AppHeaderProps {
  scrollableNodeRef?: RefObject<HTMLElement | null>;
}

const AppHeader: FC<AppHeaderProps> = ({ scrollableNodeRef }) => {
  const userData = useUser();
  const scrollToTop = () => {
    scrollableNodeRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const openModalHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    setModal("Authorization");
  };

  return (
    <header className="header" aria-label="Шапка сайта">
      <h1 className="visibility-hidden">
        Gogwarts - школа программирования на Go
      </h1>
      <nav className="header__nav" aria-label="Главная навигация">
        <div className="header__links">
          <Link
            to={"/"}
            className="header__logo-link"
            onClick={scrollToTop}
            aria-label="Главная"
          >
            <MemoLogo className="header__logo" />
          </Link>
          {/* TODO: Оптимизировать код, убрать излишки, исправить мерцание при загрузке */}
          {userData.isAuthorized ? (
            <ul className="header__nav-list">
              <li className="header__nav-item">
                <Link to={"study"} className="header__nav-link">
                  Моё обучение
                </Link>
              </li>
              <li className="header__nav-item">
                <Link to={"courses"} className="header__nav-link">
                  Курсы
                </Link>
              </li>
            </ul>
          ) : (
            <ul className="header__nav-list">
              <li className="header__nav-item">
                <a href="#aboutGo" className="header__nav-link">
                  О Go
                </a>
              </li>
              <li className="header__nav-item">
                <a href="#aboutGogwarts" className="header__nav-link">
                  О Gogwarts
                </a>
              </li>
              <li className="header__nav-item">
                <a href="#aboutLearn" className="header__nav-link">
                  Как начать учиться
                </a>
              </li>
              <li className="header__nav-item">
                <a href="#FAQ" className="header__nav-link">
                  F.A.Q.
                </a>
              </li>
            </ul>
          )}
        </div>
        {userData.isAuthorized ? (
          <Link
            to={"profile"}
            className="header__profile"
            aria-label="Профиль"
            id="Профиль"
          >
            <img
              className="header__profile-avatar"
              src={`./assets/imgs/avatars/${userData.avatar}.svg`}
              alt="аватар"
            />
            <span className="header__profile-name semi-bold">
              {userData.name}
            </span>
          </Link>
        ) : (
          <a className="header__button" href="/" onClick={openModalHandler}>
            Начать обучение
          </a>
        )}
      </nav>
    </header>
  );
};

export default AppHeader;
