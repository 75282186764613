import { FC } from "react";

interface AboutProjectSectionProps {}

const AboutProjectSection: FC<AboutProjectSectionProps> = () => {
  return (
    <section
      className="about-project-section basic-text-section"
      id="aboutGogwarts"
    >
      <img src="./assets/imgs/about-project.svg" alt="книга о проекте" />
      <div className="text-wrap">
        <h2>О проекте</h2>
        <p>
          Gogwarts — вселенная знаний по языку Go. Здесь Вы найдете
          увлекательные лекционные материалы и интерактивные тренажеры, которые
          позволят сразу применять полученные знания на практике. Каждое решение
          будет мгновенно проверено с помощью авто-тестирования, что поможет
          быстрее и эффективнее учиться.
        </p>
        <p>
          Присоединяйтесь к нам и начните свой путь в мир программирования на
          Go!
        </p>
      </div>
    </section>
  );
};

export default AboutProjectSection;
