import { FC, useRef } from "react";

interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isError?: boolean;
}

const Textarea: FC<TextareaProps> = ({ isError, ...props }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <textarea
      ref={textareaRef}
      {...props}
      className={`Textarea ${props.className ?? ""}`}
    />
  );
};

export default Textarea;
