import { FC } from "react";

interface SalaryGrowthSectionProps {}

const SalaryGrowthSection: FC<SalaryGrowthSectionProps> = () => {
  return (
    <section className="salary-growth-section">
      <h2 className="salary-growth-section__title">
        Заработок будет расти вместе с опытом
      </h2>
      <ul className="salary-growth-section__list">
        <li className="salary-growth-section__item">
          <strong className="salary-growth-section__salary semi-bold">
            120 000 ₽ +
          </strong>
          <div className="salary-growth-section__grade-info text-S semi-bold">
            <span className="salary-growth-section__grade">Junior</span>
            <span className="salary-growth-section__experience">
              после завершения курса
            </span>
          </div>
        </li>
        <li className="salary-growth-section__item">
          <strong className="salary-growth-section__salary semi-bold">
            250 000 ₽ +
          </strong>
          <div className="salary-growth-section__grade-info text-S semi-bold">
            <span className="salary-growth-section__grade">Middle</span>
            <span className="salary-growth-section__experience">
              более 2 лет
            </span>
          </div>
        </li>
        <li className="salary-growth-section__item">
          <strong className="salary-growth-section__salary semi-bold">
            300 000 ₽ +
          </strong>
          <div className="salary-growth-section__grade-info text-S semi-bold">
            <span className="salary-growth-section__grade">Senior</span>
            <span className="salary-growth-section__experience">
              более 5 лет
            </span>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default SalaryGrowthSection;
