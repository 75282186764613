/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import "./Modal.scss";
import { useClickAway, useKey } from "react-use";
import MemoCross from "assets/Cross";
import { closeModal, useModalState } from "store/useModalStore";
import {
  removeInert,
  setInert,
  setModalMaxWidth,
} from "./helpers/modalHelpers";

interface ModalProps {
  children?: JSX.Element | undefined;
}

const Modal: FC<ModalProps> = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const { modalState } = useModalState();

  const isOpened = children !== undefined;
  const modalRef = useRef(null);

  const closeModalHandler = () => {
    removeInert();
    setOpen(false);

    setTimeout(() => {
      closeModal();
      removeInert();
    }, 300);
  };

  useClickAway(modalRef, () => {
    closeModalHandler();
  });

  useKey("Escape", closeModalHandler);

  useEffect(() => {
    if (modalState === "needClose") {
      closeModalHandler();
    }
  }, [modalState]);

  useEffect(() => {
    if (children && modalRef.current) {
      if (!isOpen) {
        setTimeout(() => {
          setOpen(true);
          setInert();
        }, 1);
      }

      setModalMaxWidth(modalRef.current);
    }
  }, [modalRef, children]);

  if (!isOpened) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={`modal ${isOpen ? "opened" : "closed"}`}
      id="modal"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div className="modal__wrap" ref={modalRef}>
        {children}
        <button
          aria-label="Закрыть модальное окно"
          onClick={closeModalHandler}
          className="modal__close-btn"
        >
          <MemoCross width={30} height={30} aria-hidden="true" />
        </button>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
