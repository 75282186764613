import { FC } from "react";
import "./ProfilePage.scss";
import { deleteUserData, useUser } from "store/useUserStore";
import { Link, useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";

interface ProfilePageProps {}

const ProfilePage: FC<ProfilePageProps> = () => {
  let location = useLocation();
  const { avatar, name } = useUser();
  const activeItem = location.pathname.split("/")[2];

  const logout = () => {
    deleteUserData();
  };

  return (
    <div className="profile-page">
      <div className="profile-page__sidebar">
        <div
          className="profile-page__sidebar-name"
          aria-label="Информация о пользователе"
        >
          <img src={`/assets/imgs/avatars/${avatar}.svg`} alt="аватар" />
          <span className="text-2XL">{name}</span>
        </div>
        <hr className="profile-page__sidebar-hr" />
        <nav
          className="profile-page__sidebar-nav"
          aria-label="Навигация по профилю"
        >
          <ul className="profile-page__sidebar-list">
            <li
              className={`profile-page__sidebar-item ${
                activeItem === undefined ? "active" : ""
              }`}
            >
              <Link to="">
                <img src="/assets/imgs/settings.svg" alt="Настройки" />
                Настройки
              </Link>
            </li>
            <li
              className={`profile-page__sidebar-item ${
                activeItem === "achievements" ? "active" : ""
              }`}
            >
              <Link to="achievements">
                <img src="/assets/imgs/achievements.svg" alt="Бумага" />
                Достижения
              </Link>
            </li>
            <li
              className={`profile-page__sidebar-item ${
                activeItem === "subscription" ? "active" : ""
              }`}
            >
              <Link to="subscription">
                <img src="/assets/imgs/subscription.svg" alt="Монета" />
                Моя подписка
              </Link>
            </li>
            <li
              className={`profile-page__sidebar-item ${
                activeItem === "support" ? "active" : ""
              }`}
            >
              <Link to="support">
                <img src="/assets/imgs/support.svg" alt="Наушники" />
                Написать в поддержку
              </Link>
            </li>
          </ul>
        </nav>
        <button className="profile-page__sidebar-exitBtn" onClick={logout}>
          <img src="/assets/imgs/sign-out.svg" alt="Выход" />
          Выйти из профиля
        </button>
      </div>
      <div className="profile-page__content">
        <Outlet />
      </div>
    </div>
  );
};

export default ProfilePage;
