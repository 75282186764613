import * as React from "react";

function Logo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={131} height={27} fill="none" {...props} viewBox="0 0 131 27">
      <path
        d="M6.992.176c7.493-.902 10.753 1.88 11.447 3.385l-5.723 3.385c.693-1.379 1.352-3.836-1.561-4.137-2.914-.3-5.03 3.134-5.724 4.89-.694 3.134-.937 9.628 3.642 10.53 4.579.904 6.418-2.381 6.764-4.136 0 0-1.56-.377-6.243 0 1.04-1.129.693-2.383 0-3.01H20v9.78L15.837 25v-5.266c-.173 0-.52.806-3.122 1.88-5.827 2.408-10.78-1.88-11.967-5.64C-.794 11.083-.501 1.078 6.992.175z"
        fill="var(--purple-color)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.715 4.003c-2.534-.081-7.253 1.391-7.602 7.591-1.087 10.154 5.962 11.929 9.095 11.168 3.395-.824 7.833-4.944 4.94-14.84-1.023-3.035-4.679-3.919-6.433-3.919zm-4.094 9.672c0-5.29 2.324-6.733 4.094-6.733 2.407.1 3.452.979 3.655 6.733.162 4.614-2.29 6.081-3.655 6.245-1.379 0-4.094-.834-4.094-6.245z"
        fill="var(--purple-color)"
      />
      <path
        d="M45.532 4.95c5.994-.687 8.602 1.429 9.157 2.572l-4.578 2.573c.555-1.048 1.082-2.916-1.25-3.145-2.33-.228-4.023 2.382-4.578 3.716-.555 2.382-.75 7.318 2.914 8.004 3.663.686 5.134-1.81 5.411-3.144 0 0-1.249-.286-4.995 0 .833-.858.555-1.81 0-2.287h8.325v7.432l-3.33 3.144v-4.002c-.139 0-.416.613-2.497 1.43-4.663 1.83-8.625-1.43-9.574-4.288-1.234-3.716-1-11.32 4.995-12.006zM58 4.602h4.58l1.31 10.911L67.324 3.75l2.945 11.593 1.8-10.74h5.889c.054.17.098.545-.164.681-.327.17-2.454-.34-3.763 5.626-1.047 4.774-1.308 9.263-1.308 10.911v.682h-5.726c.545-.398 1.603-1.398 1.472-2.216-.13-.819-.818-4.887-1.145-6.82l-2.454 9.036c-1.472-.114-4.548-.239-5.071.17-.655.512 1.963-1.704 1.8-1.875-.165-.17-.819-6.308-.983-6.478 0-.41-.872-5.626-1.308-8.183L58 4.602z"
        fill="var(--purple-color)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74 26.75c1.319-2.103 3.957-6.378 3.957-6.651 0-.273 2.18-8.755 3.269-12.961L80.194 4.75h4.989L90 22.145h-3.44l-1.377-5.287h-3.097c-.516 1.877-1.652 5.662-2.064 5.799-.413.136-4.187 2.785-6.022 4.093zm9.462-16.713l-.86 3.922h2.58l-1.72-3.922zM91 5.088c2.982-.218 9.153-.458 9.978.327 1.033.982 3.957 2.617 1.377 6.378-.964 1.57-1.778 2.29-2.065 2.453L107 24.875c-2.065-1.145-6.297-3.401-6.71-3.27-.413.13-1.892-4.525-2.58-6.869H96.16v6.378c.115.109.344.36.344.49 0 .131-3.326.055-4.989 0l-.516-.49c.115-.11.447-.36.86-.49.516-.164 1.032-.982 1.032-1.309 0-.327-.172-13.573-.344-13.41-.344-.326-.86.328-1.548-.817zm8.602 5.724c.826-3.663-1.95-4.034-3.44-3.761v5.069l2.236.164c.172 0 .654-.295 1.204-1.472z"
        fill="var(--purple-color)"
      />
      <path
        d="M105 9.579l1.365-4.704h10.749c.682 1.344 2.013 4.066 1.876 4.2-.136.134-1.876-.952-2.73-1.512l-.341-.168-2.388.504v14.616l-1.195.168-1.876 3.192V7.899c-1.308-.168-4.095-.269-4.778.672L105 9.579zM122.722 5.848c5.104-2.471 7.207.343 7.62 2.059l-2.658 3.603h-.177l-.177-2.574c-.119-.572-.78-1.613-2.482-1.2-3.261 2.47-.354 4.117 2.127 4.46.418.058.74.215 1.241.515 3.721 2.23 2.894 5.377 2.126 6.864-.945 1.659-3.367 4.29-7.089 2.917-3.19-.858-3.958-2.345-4.253-3.775 1.241-1.144 3.722-3.466 3.722-3.603 0-.13.211.037.334.152.015-.027.02-.013.02.02l-.02-.02c-.037.07-.131.406-.334 1.392 0 3.569 2.126 3.66 3.19 3.26.827-.229 2.197-1.579 1.063-4.461-3.013-.515-5.376-2.574-6.557-3.947-.473-1.201-.674-4.015 2.304-5.662z"
        fill="var(--purple-color)"
      />
    </svg>
  );
}

const MemoLogo = React.memo(Logo);
export default MemoLogo;
