export const AchievementsData = [
  { name: "Начало пути", img: "", desc: "Успешно завершен первый курс по Go." },
  {
    name: "Фундаментальные знания",
    img: "",
    desc: "Освоены базовые концепции языка Go.",
  },
  {
    name: "Первые проекты",
    img: "",
    desc: "Созданы и запущены первые проекты на Go.",
  },
  {
    name: "Работа с пакетами",
    img: "",
    desc: "Изучена работа с пакетами и модулями Go.",
  },
  {
    name: "Продвинутый уровень",
    img: "",
    desc: "Успешно завершены продвинутые курсы по Go.",
  },
  {
    name: "Мастер Go",
    img: "",
    desc: "Достигнут высокий уровень мастерства в Go.",
  },
  {
    name: "Разработчик API",
    img: "",
    desc: "Созданы и задокументированы API на Go.",
  },
  {
    name: "Победитель хакатона",
    img: "",
    desc: "Победа в хакатоне с использованием Go.",
  },
  {
    name: "Гуру тестирования",
    img: "",
    desc: "Разработаны и внедрены комплексные тесты для Go приложений.",
  },
  {
    name: "Интегратор",
    img: "",
    desc: "Успешно интегрирован Go с другими технологиями.",
  },
  {
    name: "Автор библиотек",
    img: "",
    desc: "Созданы и опубликованы собственные библиотеки на Go.",
  },
  { name: "Ментор", img: "", desc: "Помощь другим в изучении Go." },
  {
    name: "Автоматизатор",
    img: "",
    desc: "Автоматизированы задачи с использованием Go.",
  },
  {
    name: "Оптимизатор",
    img: "",
    desc: "Оптимизированы производительность и память Go приложений.",
  },
  {
    name: "Мастер многопоточности",
    img: "",
    desc: "Освоена работа с горутинами и каналами.",
  },
  {
    name: "Деплойщик",
    img: "",
    desc: "Успешный деплой Go приложений в продакшен.",
  },
  {
    name: "Участник сообщества",
    img: "",
    desc: "Активное участие в Go-сообществе и форумах.",
  },
  {
    name: "Документатор",
    img: "",
    desc: "Создана полная документация для своих Go проектов.",
  },
  {
    name: "Спикер",
    img: "",
    desc: "Выступления на конференциях и митапах по Go.",
  },
  {
    name: "Контрибьютор",
    img: "",
    desc: "Внесен вклад в популярные open-source проекты на Go.",
  },
  {
    name: "Полиглот",
    img: "",
    desc: "Использование Go в сочетании с другими языками программирования.",
  },
  {
    name: "Дизайнер алгоритмов",
    img: "",
    desc: "Реализованы сложные алгоритмы и структуры данных на Go.",
  },
  {
    name: "Рефакторинг-мастер",
    img: "",
    desc: "Успешный рефакторинг кода для улучшения качества.",
  },
  {
    name: "Мобильный разработчик",
    img: "",
    desc: "Создание мобильных приложений с использованием Go.",
  },
  {
    name: "Инженер DevOps",
    img: "",
    desc: "Интеграция Go с CI/CD процессами.",
  },
];
