import { FC } from "react";

interface WhyUsSectionProps {}

const WhyUsSection: FC<WhyUsSectionProps> = () => {
  return (
    <section className="why-us-section">
      <h2 className="why-us-section__title">Почему выбирают нас?</h2>
      <div className="why-us-section__wrap">
        <img
          className="why-us-section__img"
          src="./assets/imgs/enchantress.svg"
          alt="Волшебница в шляпе"
        />
        <ul className="why-us-section__list">
          <li className="why-us-section__item">
            <h3 className="why-us-section__item-title">
              Первый раздел бесплатно
            </h3>
            <p className="why-us-section__item-text">
              Первые шаги в программировании – бесплатно! Начните свой путь без
              затрат, используя наши тренажеры. Попробуйте и убедитесь, подходит
              ли Вам программирование на Go.
            </p>
          </li>
          <li className="why-us-section__item">
            <h3 className="why-us-section__item-title">
              Качественные тренажеры
            </h3>
            <p className="why-us-section__item-text">
              Максимум знаний в удобном формате! Наши курсы отличаются не только
              превосходной теорией, но и практическими заданиями, чтобы Вы могли
              моментально применять усвоенные навыки.
            </p>
          </li>
          <li className="why-us-section__item">
            <h3 className="why-us-section__item-title">Низкие цены</h3>
            <p className="why-us-section__item-text">
              Обучение не должно быть дорогим! У нас доступны разнообразные
              варианты оплаты, включая оплату главы, раздела или даже подписки
              на месяц или навсегда – и все это по приемлемым ценам, чтобы
              каждый мог позволить себе комфортное обучение.
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default WhyUsSection;
