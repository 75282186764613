import { create } from "zustand";
import { devtools } from "zustand/middleware";

type UserData = {
  name?: string;
  email: string;
  nickname?: string;
  avatar?: "M-1" | "M-2" | "M-3" | "F-1" | "F-2" | "F-3" | "";
};

interface UserStore extends UserData {
  role: "admin" | "user";
  isAuthorized: boolean;
  isExited?: boolean;
}

const initialState: UserStore = {
  name: "",
  email: "",
  nickname: "",
  avatar: "",
  role: "user",
  isAuthorized: false,
};

const useUserStore = create<UserStore>()(
  devtools<UserStore>((set) => ({
    ...initialState,
  }))
);

export const useUser = () =>
  useUserStore((state) => ({
    name: state.name,
    email: state.email,
    nickname: state.nickname,
    avatar: state.avatar,
    role: state.role,
    isAuthorized: state.isAuthorized,
  }));

export const useUserAuth = () =>
  useUserStore((state) => ({
    isAuthorized: state.isAuthorized,
    role: state.role,
    isExited: state.isExited,
  }));

export const setUserData = (userData: UserData) => {
  localStorage.setItem("user", JSON.stringify(userData));

  useUserStore.setState(
    { ...userData, isAuthorized: true },
    false,
    "user/setUserData"
  );
};

export const deleteUserData = () => {
  localStorage.removeItem("user");

  useUserStore.setState(
    { ...initialState, isExited: true },
    false,
    "user/setUserData"
  );
};
