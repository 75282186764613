import { FC } from "react";
import Fireworks, { FireworksHandlers } from "@fireworks-js/react";
import { useRef, useState } from "react";

interface AppFooterProps {}

const AppFooter: FC<AppFooterProps> = () => {
  const [isFireworkStart, setFireworkStart] = useState(false);
  const ref = useRef<FireworksHandlers>(null);

  const toggle = () => {
    setFireworkStart(true);
    setTimeout(() => {
      setFireworkStart(false);
    }, 5000);
  };

  return (
    <footer className="footer">
      <ul className="footer__nav">
        <li className="footer__nav-item">
          <a href="#aboutGogwarts" className="footer__nav-link">
            О нас
          </a>
        </li>
        <li className="footer__nav-item">
          <a href="#contacts" className="footer__nav-link">
            Контакты
          </a>
        </li>
        <li className="footer__nav-item">
          <a href="#support" className="footer__nav-link">
            Поддержка
          </a>
        </li>
        <li className="footer__nav-item">
          <a href="#reviews" className="footer__nav-link">
            Отзывы
          </a>
        </li>
        <li className="footer__nav-item">
          <a href="#FAQ" className="footer__nav-link">
            F.A.Q.
          </a>
        </li>
      </ul>
      <div className="footer__section">
        <p className="footer__section-copyright" onClick={() => toggle()}>
          «Волшебство - оно рядом» © 2024 Gogwarts
        </p>
        <a
          className="footer__section-doc"
          href="./assets/docs/Правилами и ограничениями.pdf"
        >
          Пользовательское соглашение
        </a>
      </div>
      {isFireworkStart && (
        <Fireworks
          ref={ref}
          options={{
            opacity: 1,
            sound: {
              enabled: true,
              files: [
                "https://fireworks.js.org/sounds/explosion0.mp3",
                "https://fireworks.js.org/sounds/explosion1.mp3",
                "https://fireworks.js.org/sounds/explosion2.mp3",
              ],
              volume: { min: 2, max: 3 },
            },
            mouse: {
              click: true,
              move: false,
              max: 1,
            },
          }}
          style={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            position: "fixed",
            background: "transparent",
          }}
        />
      )}
    </footer>
  );
};

export default AppFooter;
