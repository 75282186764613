import * as React from "react";

function Arrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={30} height={19} fill="none" {...props} viewBox="0 0 30 19">
      <path
        d="M0.929325 0.734822C1.9417 -0.210061 3.52319 -0.174109 4.49158 0.815802L11.8789 8.36732C13.0466 9.56091 14.9641 9.57145 16.1448 8.39076L23.75 0.785522C24.7165 -0.180976 26.2835 -0.180976 27.25 0.785522C28.2165 1.75202 28.2165 3.31902 27.25 4.28552L16.1213 15.4142C14.9497 16.5858 13.0503 16.5858 11.8787 15.4142L0.866123 4.40164C-0.153935 3.38158 -0.125283 1.71912 0.929325 0.734822Z"
        fill="#533D4D"
      />
    </svg>
  );
}

const MemoArrow = React.memo(Arrow);
export default MemoArrow;
