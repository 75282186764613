import { FC, useState } from "react";
import MemoArrow from "assets/Arrow";
import questionsData from "./FaqSectionData.json";

interface FaqSectionProps {}
interface FaqSectionItemProps {
  title: string;
  text: string;
}

export const FaqSectionItem: FC<FaqSectionItemProps> = ({ title, text }) => {
  const [isOpen, setOpen] = useState(false);

  const toggleQuestionState = () => {
    setOpen((prev) => !prev);
  };

  return (
    <li className={`faq-section__item ${isOpen ? "opened" : ""}`}>
      <summary
        className="faq-section__item-header"
        onClick={toggleQuestionState}
      >
        <strong className="faq-section__item-title text-L">{title}</strong>
        <span className="faq-section__item-icon">
          <MemoArrow aria-label="стрелка для открытия\закрытия вопроса" />
        </span>
      </summary>
      {isOpen && <div className="faq-section__item-text">{text}</div>}
    </li>
  );
};

const FaqSection: FC<FaqSectionProps> = () => {
  return (
    <section className="faq-section" id="FAQ">
      <h2 className="faq-section__title">Возможные вопросы</h2>
      <hr className="faq-section__separator" />
      <ul className="faq-section__list">
        {questionsData.map((item, index) => {
          return (
            <FaqSectionItem key={index} title={item.title} text={item.text} />
          );
        })}
      </ul>
    </section>
  );
};

export default FaqSection;
