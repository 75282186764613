import MemoGopher from "assets/Gopher";
import { FC } from "react";

interface DeveloperRoleSectionProps {}

const DeveloperRoleSection: FC<DeveloperRoleSectionProps> = () => {
  return (
    <section className="developer-role-section basic-text-section" id="aboutGo">
      <div className="text-wrap">
        <h2 className="developer-role-section__title">
          Чем занимается Go-разработчик?
        </h2>
        <p>
          Программисты на Go занимаются разработкой backend-части приложений,
          ответственной за обработку данных и выполнение сложных операций.
        </p>
        <br />
        <p>
          На Go создают проекты в Wildberries, Ozon, Яндексе, Сбербанке,
          Тинькофф и других крупных IT-компаниях на российском и международном
          рынке.
        </p>
      </div>
      <MemoGopher />
    </section>
  );
};

export default DeveloperRoleSection;
