import { FC } from "react";

interface HowToStartSectionProps {}

const HowToStartSection: FC<HowToStartSectionProps> = () => {
  return (
    <section className="how-to-start-section" id="aboutLearn">
      <h2 className="how-to-start-section__title">Как начать учиться</h2>
      <ul className="how-to-start-section__imgs-list">
        <li className="how-to-start-section__imgs-item">
          <img src="./assets/imgs/register.svg" alt="Регистрация" />
        </li>
        <hr className="separator" />
        <li className="how-to-start-section__imgs-item">
          <img src="./assets/imgs/learn.svg" alt="Обучение" />
        </li>
        <hr className="separator" />
        <li className="how-to-start-section__imgs-item">
          <img src="./assets/imgs/practice.svg" alt="Практика" />
        </li>
      </ul>
      <ul className="how-to-start-section__list">
        <li className="how-to-start-section__item">
          <h3 className="how-to-start-section__item-title text-L">
            Регистрация
          </h3>
          <p className="how-to-start-section__item-text">
            Зарегистрируйся, чтобы получить доступ к курсу по Go, настроенному
            под твои предпочтения к формату
          </p>
        </li>
        <li className="how-to-start-section__item">
          <h3 className="how-to-start-section__item-title text-L">Обучение</h3>
          <p className="how-to-start-section__item-text">
            Читай мини-лекции по программированию по Go: учить новую теорию
            порциями гораздо легче
          </p>
        </li>
        <li className="how-to-start-section__item">
          <h3 className="how-to-start-section__item-title text-L">Практика</h3>
          <p className="how-to-start-section__item-text">
            Решай задачи и сразу получай результат - здесь онлайн-обучение Go
            основано на усиленной практике
          </p>
        </li>
      </ul>
    </section>
  );
};

export default HowToStartSection;
