import { FC, useEffect } from "react";
import "./LandingPage.scss";
import FaqSection from "./components/FaqSection";
import DeveloperRoleSection from "./components/DeveloperRoleSection";
import WelcomeSection from "./components/WelcomeSection";
import WhyGoSection from "./components/WhyGoSection";
import DemandSection from "./components/DemandSection";
import SalaryGrowthSection from "./components/SalaryGrowthSection";
import AboutProjectSection from "./components/AboutProjectSection";
import WhyUsSection from "./components/WhyUsSection";
import FirstSimulatorSection from "./components/FirstSimulatorSection";
import HowToStartSection from "./components/HowToStartSection";
import JoinUsSection from "./components/JoinUsSection";

interface LandingPageProps {}

const LandingPage: FC<LandingPageProps> = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div className="landing-page">
      <WelcomeSection />
      <DeveloperRoleSection />
      <WhyGoSection />
      <DemandSection />
      <SalaryGrowthSection />
      <AboutProjectSection />
      <WhyUsSection />
      <FirstSimulatorSection />
      <HowToStartSection />
      <JoinUsSection />
      <FaqSection />
    </div>
  );
};

export default LandingPage;
