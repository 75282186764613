import { StrictMode } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./reset.css";
import "./index.scss";
import "./animations.scss";
import reportWebVitals from "./reportWebVitals";

import App from "components/App";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import LandingPage from "pages/LandingPage";
import ProfilePage from "pages/ProfilePage";
import ModalProvider from "components/ModalProvider";
import ProfileAchievements from "modules/ProfileAchievements";
import ProfileSettings from "modules/ProfileSettings";
import ProfileSubscription from "modules/ProfileSubscription";
import ProfileSupport from "modules/ProfileSupport";
import ProtectedRoute from "components/ProtectedRouter/ProtectedRouter";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true, // 1234
        element: <LandingPage />,
      },
      {
        element: <ProtectedRoute requiredRole="user" />,
        children: [
          {
            path: "profile",
            element: <ProfilePage />,
            children: [
              {
                path: "",
                element: <ProfileSettings />,
              },
              {
                path: "achievements",
                element: <ProfileAchievements />,
              },
              {
                path: "subscription",
                element: <ProfileSubscription />,
              },
              {
                path: "support",
                element: <ProfileSupport />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <StrictMode>
    <ModalProvider>
      <RouterProvider router={router} />
    </ModalProvider>
  </StrictMode>
);

reportWebVitals();
